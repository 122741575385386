angular.module("aerosApp").controller("TestLimitsCtrl",
    ["$scope", "aerosApi", "$state", "$stateParams", "$timeout", 'Notification', 'newTestLimitModal', 'fiberTypes', "$translate",
        function ($scope, aerosApi, $state, $stateParams, $timeout, Notification, newTestLimitModal, fiberTypes, $translate) {
            $scope.$watch("commonReady", function (newVal) {
                if (!newVal) return;
//		if (!$scope.allow['viewLimits']) return $scope.reject();
                $scope.fiberTypes = fiberTypes;
                $scope.filter = "";
                $scope.testLimits = [];
                $scope.messages = {
                    "updateSuccess": "Updated test limit",
                    "updateFail": "Failed to update limit",
                    "copyOf": "Copy of",
                    "createSuccess": "Test Limit created.",
                    "createFailure": "Failed to create limit - Duplicate."
                };
                _.each($scope.messages, function (message, key) {
                    $translate(message).then(function (translation) {
                        $scope.messages[key] = translation;
                    });
                });

                $scope.statusFilter = "active";
                $scope.customFilter = "all";
                $scope.orlFilter = "all";
                function loadTestLimits() {
                    aerosApi.getTestLimitsWithArchived($scope.organization.id)
                        .then(function (data) {
                            if (!$scope.allow['deactivateLimits'] || $scope.statusFilter === "active") {
                                data.limits = _.filter(data.limits, function (limit) {
                                    return !limit.archived;
                                });
                            } else if ($scope.statusFilter === "hidden") {
                                data.limits = _.filter(data.limits, function (limit) {
                                    return limit.archived;
                                });
                            } // "both" won't reduce the list

                            //filter out for custom
                            if ($scope.customFilter === "custom") {
                                data.limits = _.filter(data.limits, function (limit) {
                                    return limit.isCustomTestLimit === true;
                                });
                            } else if ($scope.customFilter === "default") {
                                data.limits = _.filter(data.limits, function (limit) {
                                    return limit.isCustomTestLimit === false;
                                });
                            }

                            if ($scope.orlFilter === "ORL") {
                                data.limits = _.filter(data.limits, function (limit) {
                                    return limit.lossThresholdType === 'ORLLoss';
                                });
                            } else if ($scope.orlFilter === "OLTS") {
                                data.limits = _.filter(data.limits, function (limit) {
                                    return limit.lossThresholdType !== 'ORLLoss';
                                });
                            }
                            $scope.testLimits = data.limits;
                        });
                };
                $scope.updateStatusFilter = function (statusFilter) {
                    $scope.statusFilter = statusFilter;
                    loadTestLimits();
                };

                $scope.updateCustomFilter = function (customFilter) {
                    $scope.customFilter = customFilter;
                    loadTestLimits();
                }

                $scope.updateORLFilter = function (orlFilter) {
                    $scope.orlFilter = orlFilter;
                    loadTestLimits();
                }

                var api = {
                    toggleArchived: function (limit) {
                        if (!$scope.allow["deactivateLimits"]) return;
                        aerosApi.toggleTestLimitStatus(limit.key, $scope.organization.id)
                            .then(function (resp) {
                                var ok = resp.status >= 200 && resp.status < 300;
                                if (ok) {
                                    loadTestLimits();
                                    Notification.success($scope.messages['updateSuccess'] + " '" + limit.name + "'.");
                                }
                                else {
                                    Notification.error($scope.messages['updateFail'] + " '" + limit.name + "'.");
                                }
                            });
                    },
                    copyLimit: function (limit) {
                        if (!$scope.allow["createLimits"]) return;
                        var nameLength = 40;
                        var newLimit = angular.copy(limit);

                        delete newLimit.key;

                        newLimit.fiberType = fiberTypes.find(function (fiberType) {
                            return fiberType.id === newLimit.fiberType;
                        });

                        var tempName = $scope.messages['copyOf'] + " " + limit.name;
                        newLimit.name = tempName.length > nameLength ? tempName.substring(0, nameLength) : tempName;
                        newTestLimitModal.open(newLimit).then(function () {
                            Notification.success($scope.messages['createSuccess']);
                            loadTestLimits();
                        });
                    },
                    createNewTestLimit: function () {
                        if (!$scope.allow["createLimits"]) return;
                        var limit = {
                            lossThresholdType: 'None'
                        };
                        newTestLimitModal.open(limit).then(function (response) {
                            if (response.code === 500) {
                                // Currently 500 code means it is a duplicate
                                // TODO: In the future we can handle other codes and make a one that is specific to duplicate
                                Notification.error($scope.messages['createFailure']);
                            } else {
                                Notification.success($scope.messages['createSuccess']);
                                loadTestLimits();
                            }

                        });
                    }
                };
                // Init
                loadTestLimits();
                angular.extend($scope, api);
            });
        }]);